<template>
  <div class="h100 vh100 d-flex d-col">
    <TopToolbar />
    <div class="col align-center grow h100">
      <div class="page h100">
        <div v-if="loading" class="center h100">
          <pulse-loader
            :loading="loading"
            :color="'var(--green)'"
            size="30px"
          ></pulse-loader>
        </div>
        <pdf
          v-for="i in numPages"
          :key="i"
          :src="src"
          :page="i"
          style="display: inline-block; width: 100%"
        ></pdf>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import TopToolbar from "@/components/Toolbars/TopToolbar.vue";

export default {
  components: {
    pdf,
    TopToolbar,
  },
  data() {
    return {
      src: null,
      numPages: undefined,
      loading: true,
    };
  },
  mounted() {
    this.src = pdf.createLoadingTask(`/docs/${this.$route.params.file}`);
    this.src.promise.then((pdf) => {
      this.loading = false;
      this.numPages = pdf.numPages;
    });
  },
};
</script>

<style scoped>
.page {
  max-width: 768px;
  max-height: calc(100vh - 60px);
  overflow: overlay;
}
</style>